import React, { useCallback, useRef, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Edit2, PlusSquare } from "react-feather";
import UserModal from "./UserModal";
import Popconfirm from "../../components/Popconfirm";
import { addUserToRole, addUserToRoles, registerUser, removeUserRoles, updateUser } from "../../services/users";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import * as actions from "../../redux/actions/app";

const Users = ({ userType = "User" }) => {
  const { [`${userType.toLowerCase()}s`]: users } = useAppSelector(
    (state) => state.appData
  );
  const [userToEdit, setUserToEdit] = useState();
  const [userToDelete, setUserToDelete] = useState("");
  const dispatch = useAppDispatch();
  const userModalRef = useRef();

  const handleSubmit = async (values) => {
    if (userToEdit) {
      await removeUserRoles(userToEdit.email);
      await addUserToRoles(values.email, values.role);

      // const pList = values.role.map((role) => {
      //   return addUserToRole(values.email, role);
      // });
      await updateUser(values);
      // await Promise.all(pList);
      dispatch(actions[`load${userType}s`]());
    } else {
      await registerUser(values);
      if (values.role !== "user") {
        await addUserToRole(values.email, values.role);
      }
      dispatch(actions[`load${userType}s`]());
    }
  };

  const handleUserEdit = (row) => {
    setUserToEdit(row);
    userModalRef.current.open();
  };

  const handleUserDelete = () => {
    setUserToDelete("");
  };

  const closeUserModal = useCallback(() => setUserToEdit(undefined), []);

  return (
    <>
      <Popconfirm
        show={!!userToDelete}
        onCancel={() => setUserToDelete("")}
        title={`Delete this ${userType}?`}
        onOk={handleUserDelete}
      />
      <UserModal
        ref={userModalRef}
        onSubmit={handleSubmit}
        userType={userType}
        onClose={closeUserModal}
        initialValues={userToEdit}
      />
      <div className="d-flex justify-content-between mb-3">
        <h2>{`${userType}s`}</h2>
        <Button
          className="float-end"
          onClick={() => userModalRef.current.open()}
        >
          <PlusSquare size={20} className="me-2" />
          Add
        </Button>
      </div>
      <Card>
        <Table striped>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Color</th>
              <th>Active</th>
              <th style={{ width: 120 }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.userName}</td>
                <td>{user.email}</td>
                <td>
                  {user.color ? (<div style={{ borderRadius: '5px', backgroundColor: user.color, height: '20px', width: '20px' }}></div>) : "N/A"}
                </td>
                <td>
                  {user.active === true ? 'Yes' : 'No'}
                </td>
                <td className="table-action">
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => handleUserEdit(user)}
                  >
                    <Edit2 size={18} />
                  </Button>
                  {/* <Button
                    variant="link"
                    size="sm"
                    onClick={() => setUserToDelete(user.id)}
                  >
                    <Trash size={18} className="align-middle text-danger" />
                  </Button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default Users;
