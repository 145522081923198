import axios from "axios";
import { SERVER_URL } from "../../../configs";
import { getPortfolios } from "../../../services/portfolio";
import { getPropertySubTypes } from "../../../services/propertySubType";
import { getCustomerRules } from "../../../services/customerRules";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_PROPERTIES = "LOAD_PROPERTIES";
export const LOAD_CUSTOMER_RULES = "LOAD_CUSTOMER_RULES";
export const LOAD_PROPERTY_SUB_TYPES = "LOAD_PROPERTY_SUB_TYPES";
export const LOAD_JOB_PURPOSES = "LOAD_JOB_PURPOSES";
export const LOAD_JOB_ASSIGNMENT_TYPES = "LOAD_JOB_ASSIGNMENT_TYPES";
export const LOAD_QUOTE_TIME_SLOTS = "LOAD_QUOTE_TIME_SLOTS";
export const LOAD_VALUE_OPTIONS = "LOAD_VALUE_OPTIONS";
export const LOAD_INSPECTORS = "LOAD_INSPECTORS";
export const LOAD_SIGNING_APPRAISERS = "LOAD_SIGNING_APPRAISERS";
export const LOAD_PORTFOLIOS = "LOAD_PORTFOLIOS";
export const LOAD_TICKET_STATUS_TYPES = "LOAD_TICKET_STATUS_TYPES";
export const LOAD_TICKET_STEP_TYPES = "LOAD_TICKET_STEP_TYPES";
export const LOAD_TICKET_GROUP_STATUS_TYPES = "LOAD_TICKET_GROUP_STATUS_TYPES";

export const loadUsers = () => (dispatch) => {
  axios
    .get(`${SERVER_URL}/api/User/GetUsers`)
    .then((res) => {
      dispatch({ type: LOAD_USERS, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const loadInspectors = () => (dispatch) => {
  axios
    .get(`${SERVER_URL}/api/User/GetInspectors`)
    .then((res) => {
      dispatch({ type: LOAD_INSPECTORS, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const loadSigningAppraisers = () => (dispatch) => {
  axios.get(`${SERVER_URL}/api/User/GetSigningAppraisers`)
    .then((res) => {
      dispatch({ type: LOAD_SIGNING_APPRAISERS, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });
}

export const loadPortfolios = () => (dispatch) =>
  getPortfolios()
    .then((data) => dispatch({ type: LOAD_PORTFOLIOS, payload: data }))
    .catch(console.error);

export const loadCustomerRules = () => (dispatch) =>
  getCustomerRules()
    .then((data) => dispatch({ type: LOAD_CUSTOMER_RULES, payload: data }))
    .catch(console.error);

export const loadPropertySubTypes = () => (dispatch) =>
  getPropertySubTypes()
    .then((data) => dispatch({ type: LOAD_PROPERTY_SUB_TYPES, payload: data }))
    .catch(console.error);

export const loadAppData = () => (dispatch) => {
  loadUsers()(dispatch);

  axios
    .get(`${SERVER_URL}/api/TicketGroupStatusType/GetTicketGroupStatusTypes`)
    .then((res) => {
      dispatch({ type: LOAD_TICKET_GROUP_STATUS_TYPES, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });

  axios
    .get(`${SERVER_URL}/api/TicketStatusType/GetTicketStatusTypes`)
    .then((res) => {
      dispatch({ type: LOAD_TICKET_STATUS_TYPES, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });

  axios
    .get(`${SERVER_URL}/api/TicketStepType/GetTicketStepTypes`)
    .then((res) => {
      dispatch({ type: LOAD_TICKET_STEP_TYPES, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });

  loadInspectors()(dispatch)

  loadSigningAppraisers()(dispatch);

  loadPortfolios()(dispatch);

  loadCustomerRules()(dispatch);

  axios
    .get(`${SERVER_URL}/api/PropertyType/GetPropertyTypes`)
    .then((res) => {
      dispatch({ type: LOAD_PROPERTIES, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });

  loadPropertySubTypes()(dispatch);

  axios
    .get(`${SERVER_URL}/api/JobPurpose/GetJobPurposes`)
    .then((res) => {
      dispatch({ type: LOAD_JOB_PURPOSES, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });

  axios
    .get(`${SERVER_URL}/api/JobAssignmentType/GetJobAssignmentTypes`)
    .then((res) => {
      dispatch({ type: LOAD_JOB_ASSIGNMENT_TYPES, payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });

  axios.get(`${SERVER_URL}/api/ValueType/GetValueTypes`).then((res) => {
    let _valueOptions = []
    res.data.forEach((option) => {
      _valueOptions.push({ label: option.name, value: option.id })
    })
    dispatch({ type: LOAD_VALUE_OPTIONS, payload: _valueOptions });
  }).catch((err) => {
    console.error(err);
  });

  axios.get(`${SERVER_URL}/api/QuoteTimeSlot/GetQuoteTimeSlots`).then((res) => {
    dispatch({ type: LOAD_QUOTE_TIME_SLOTS, payload: res.data });
  }).catch((err) => {
    console.error(err);
  });
};